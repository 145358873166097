@if (ticket(); as ticket) {
    @if (license(); as license) {
        <div class="flex flex-col gap-4">
            <!-- title -->
            <div class="flex flex-row justify-between items-center">
                <div class="text-2xl">{{ ticket.title }}</div>
                <div class="text-base">{{ 'Tickets.Edit.TimeSpent' | transloco }} {{ timeSpent() }}</div>
            </div>

            <!-- assignment and priority -->
            <div class="flex flex-row justify-between">
                @if(!!ticket.assignedToUser) {
                    <div>
                        <div class="text-base">{{ 'Tickets.Edit.AssignedTo' | transloco }}</div>
                        <div class="text-lg">{{ ticket.assignedToUser }}</div>
                    </div>
                }
                <div>
                    <div class="text-base">{{ 'Tickets.Edit.Priority' | transloco }}</div>
                    <div class="text-lg">{{ ticket.priorityDescription }}</div>
                </div>
            </div>

            <!-- license and db info -->
            <div class="border border-on-app-light border-solid rounded-xl p-5">
                <arc-license-db-info [license]="license" [isDarkTheme]="true" />
            </div>

            <!-- additional data -->
            <div class="border border-on-app-light border-solid rounded-xl p-5">
                <!-- ext json -->
                <div class="pb-4">
                    <p class="text-xs pb-1">
                        {{ 'Tickets.Details.AdditionalData.Title' | transloco }}
                    </p>
                    <div class="grid grid-cols-[auto_1fr] gap-x-4">
                        <span class="font-bold">{{ 'Tickets.Details.AdditionalData.Category' | transloco }}</span>
                        <span>{{ ticket.categoryHelpTitle }}</span>
                        <span class="font-bold">{{ 'Tickets.Details.AdditionalData.Branch' | transloco }}</span>
                        @if (!!ticket.branchName) {
                            <div class="flex items-center">
                                <span>{{ ticket.branchName }}</span>
                                @if (!!licenseUrl()) {
                                    <a class="ml-2" target="_blank" href="{{ licenseUrl() }}/settings/branches?Id={{ ticket.branchId }}">
                                        <arc-icon icon="open_in_new" />
                                    </a>
                                }
                            </div>
                        } @else {
                            <span>-</span>
                        }
                        <span class="font-bold">{{ 'Tickets.Details.AdditionalData.PointOfSale' | transloco }}</span>
                        @if (!!ticket.pointOfSaleName) {
                            <div class="flex items-center">
                                <span>{{ ticket.pointOfSaleName }}</span>
                                @if (!!licenseUrl()) {
                                    <a class="ml-2" target="_blank" href="{{ licenseUrl() }}/settings/pos?Id={{ ticket.pointOfSaleId }}">
                                        <arc-icon icon="open_in_new" />
                                    </a>
                                }
                            </div>
                        } @else {
                            <span>-</span>
                        }
                        <span class="font-bold">{{ 'Tickets.Details.AdditionalData.FromTime' | transloco }}</span>
                        <span>{{ (ticket.fromTime | arcDate : 'short') ?? '-' }}</span>
                    </div>
                </div>

                <!-- download logs button -->
                <arc-button class="w-full" customClasses="w-full !text-white !border-white"
                    actionButtonKey="TicketDownloadLogsContextAction"
                    actionButtonTitleKey="Tickets.Details.DownloadLogs" [currentData]="ticket" type="stroked">
                </arc-button>
            </div>

            <!-- license details -->
            <div class="border border-on-app-light border-solid rounded-xl p-5">
                <arc-license-details [license]="license" />
            </div>

            <!-- ticket list -->
            <div>
                <div class="text-base">{{ 'Tickets.Edit.Tickets' | transloco }}</div>
                <arc-ticket-list />
            </div>
        </div>
    } @else {
        <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
    }
} @else {
    <ng-container *ngTemplateOutlet="loadingSpinner"></ng-container>
}

<ng-template #loadingSpinner>
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32" />
    </div>
</ng-template>