import { Component, inject } from '@angular/core';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { FeedbackListModel } from '../../../models/responses/feedback-list.model';
import { FeedbackModel } from '../../../models/feedback.model';
import { FeedbacksStore } from '../../../services/stores/feedbacks.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { DateColumnModel } from '../../../../components/dynamic-table/models/column-types/date-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { CustomColumnModel } from '../../../../components/dynamic-table/models/column-types/custom-column.model';
import { FeedbackStatusComponent } from './components/feedback-status/feedback-status.component';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { FeedbackTextComponent } from './feedback-widgets/feetback-text/feedback-text.component';
import { FeedbackTypeColumnComponent } from './components/feedback-type/feedback-type-column.component';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';

@Component({
    selector: 'arc-feedback',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './feedback.component.scss'
})
export class FeedbackComponent extends PageListViewComponent<FeedbackListModel, FeedbackModel> {
    private readonly feedbackStore = inject(FeedbacksStore);

    constructor() {
        super();
        this.config = new TableListViewConfigModel<FeedbackListModel, FeedbackModel>({
            entityName: 'Feedback',
            availableColumns: {
                createdDate: new DateColumnModel({
                    columnTitleKey: 'Feedback.List.CreatedDate',
                    propertyName: 'createdDate',
                    format: 'short',
                    widthPixels: 180
                }),
                isProcessed: new CustomColumnModel({
                    columnTitleKey: 'Feedback.List.Status',
                    propertyName: 'isProcessed',
                    customComponent: FeedbackStatusComponent,
                    widthPixels: 160
                }),
                createdByEmail: new StackedColumnModel({
                    columnTitleKey: 'Feedback.List.CreatedByEmail',
                    propertyName: 'createdByEmail',
                    propertyName2: 'licenseHolderName',
                    widthPixels: 400
                }),
                type: new CustomColumnModel({
                    columnTitleKey: 'Feedback.List.Type',
                    widthPixels: 160,
                    customComponent: FeedbackTypeColumnComponent
                }),
                area: new StringColumnModel({
                    columnTitleKey: 'Feedback.List.Area',
                    propertyName: 'areaName',
                    widthPixels: 150
                }),
                featureTitle: new StringColumnModel({
                    columnTitleKey: 'Feedback.List.Feature',
                    propertyName: 'featureTitle',
                    widthPixels: 150
                })
            },
            defaultColumnOrder: ['createdDate', 'isProcessed', 'createdByEmail', 'featureTitle', 'type', 'area'],
            defaultSort: new ColumnSortModel({
                column: 'createdDate',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                text: new TableListDetailWidgetModel({
                    name: 'Feedback.Widgets.TextWidget',
                    component: FeedbackTextComponent,
                    shouldHideHeader: true,
                    takeFullSizeIfOnly: true
                })
            },
            showCheckboxes: true,
            defaultDetailWidgetOrder: ['text'],
            store: this.feedbackStore,
            buttonsVisibility: new ButtonsVisibilityModel({ hasCreate: false, hasEdit: false })
        });
    }
}
