import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StoreConfig } from '../../../core/models/store.config';
import { BaseCrudStore } from '../../../core/abstractions/base-crud.store';
import { HelpArticleModel } from '../../models/responses/help-article.model';
import { HelpArticleListModel } from '../../models/responses/help-article-list.model';
import { ApiResponseModel } from '../../models/responses/api-response.model';
import { KeyValueModel } from '../../../core/models/key-value.model';
import { HelpArticleEditModel } from '../../models/requests/help-article-edit.model';
import { HelpArticleViewModel } from '../../models/responses/help-article-view.model';

@Injectable({
    providedIn: 'root'
})
export class HelpArticlesStore extends BaseCrudStore<HelpArticleModel, HelpArticleListModel, HelpArticleEditModel> {
    constructor() {
        super(new StoreConfig({ baseController: 'help-articles' }));
    }

    getHelpTopics(): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl('help-topics'));
    }

    getHelpSubTopics(topicId: number): Observable<ApiResponseModel<KeyValueModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<KeyValueModel[]>>(this.getUrl(`help-subtopics/${topicId}`));
    }

    getPublicArticleById(id: string): Observable<ApiResponseModel<HelpArticleViewModel>> {
        return this._requestService.makeGet<ApiResponseModel<HelpArticleViewModel>>(this.getUrl(`view/${id}`));
    }

    getAllPublicArticles(): Observable<ApiResponseModel<HelpArticleViewModel[]>> {
        return this._requestService.makeGet<ApiResponseModel<HelpArticleViewModel[]>>(this.getUrl('view'));
    }
}
