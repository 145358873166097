export enum CustomerInteractionMoodsEnum {
    Good = 0,
    Bad = 1
}

export class CustomerInteractionMoodsEnumExtensions {
    static getColor(value: CustomerInteractionMoodsEnum): string {
        switch (value) {
            case CustomerInteractionMoodsEnum.Good:
                return 'bg-success-light text-on-success-light';
            default:
                return 'bg-error-light text-on-error-light';
        }
    }
}
