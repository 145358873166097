import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, forwardRef, inject, input, viewChild } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import EasyMDE from 'easymde';

import { environment } from '../../environments/environment';
import { Tools } from '../../core/utils/tools';
import { SecurityStorage } from '../../core/services/storages/security.storage';
import { BaseControlValueAccessor } from '../../core/abstractions/base-control-value-accessor';

@Component({
    selector: 'arc-markdown-editor',
    templateUrl: './markdown-editor.component.html',
    styleUrl: './markdown-editor.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MarkdownEditorComponent),
            multi: true
        }
    ]
})
export class MarkdownEditorComponent extends BaseControlValueAccessor<string> implements AfterViewInit, OnDestroy {
    @HostBinding('class')
    get hostClasses(): string {
        return this.styleClass();
    }

    readonly markdownEditor = viewChild.required('arcMarkdownEditor', { read: ElementRef });

    styleClass = input<string>('mde-default');
    allowImageUpload = input<boolean>(true);
    easyMDE?: EasyMDE;

    private securityStorage = inject(SecurityStorage);

    ngAfterViewInit(): void {
        const userInfo = this.securityStorage.getUserInfo();
        const imageEndpointParams = new HttpParams().set('token', userInfo?.token || '');
        this.easyMDE = new EasyMDE({
            element: this.markdownEditor().nativeElement,
            initialValue: this.value ?? '',
            uploadImage: this.allowImageUpload(),
            imagePathAbsolute: true,
            imageMaxSize: 1024 * 1024 * 10,
            spellChecker: false,
            nativeSpellcheck: false,
            imageUploadEndpoint: `${Tools.Url.getUrl('blobs', environment.baseUrl)}?${imageEndpointParams.toString()}`
        });

        this.easyMDE.codemirror.on('blur', () => this.valueChanged(this.easyMDE?.value()));
    }

    override ngOnDestroy(): void {
        super.ngOnDestroy();
        this.easyMDE?.cleanup();
    }

    override writeValue(value: string): void {
        super.writeValue(value);
        this.easyMDE?.value(value ?? '');
    }

    focus(): void {
        this.easyMDE?.codemirror.focus();
    }
}
