import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, Validators } from '@angular/forms';
import { Component, inject, signal } from '@angular/core';
import { Observable, tap, forkJoin, finalize } from 'rxjs';

import { BaseComponent } from '../../abstractions/base.component';
import { TranslationDialogDataModel } from './models/translation-dialog-data.model';
import { ArcFormControl } from '../../../core/utils/arc-form-control';
import { ApiResponseModel } from '../../../app/models/responses/api-response.model';
import { GeneralDataStore } from '../../../app/services/stores/general-data.store';
import { AlertService } from '../../../core/services/alert.service';

@Component({
    selector: 'arc-translation-dialog',
    templateUrl: './translation-dialog.component.html',
    styleUrls: ['./translation-dialog.component.scss']
})
export class TranslationDialogComponent extends BaseComponent {
    readonly data: TranslationDialogDataModel = inject(MAT_DIALOG_DATA);
    readonly isTranslating = signal(false);

    formGroup = new FormGroup({
        valueDe: new ArcFormControl(this.data.valueDe, this.data.currentLanguageCode === 'de' ? Validators.required : []),
        valueEn: new ArcFormControl(this.data.valueEn, this.data.currentLanguageCode === 'en' ? Validators.required : []),
        valueFr: new ArcFormControl(this.data.valueFr, this.data.currentLanguageCode === 'fr' ? Validators.required : []),
        valueIt: new ArcFormControl(this.data.valueIt, this.data.currentLanguageCode === 'it' ? Validators.required : [])
    });

    private readonly dialogRef = inject(MatDialogRef<TranslationDialogComponent>);
    private readonly generalDataStore = inject(GeneralDataStore);
    private readonly alertService = inject(AlertService);

    onSave(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (this.formGroup.invalid) {
            return;
        }
        this.dialogRef.close(this.formGroup.value);
    }

    translateEmptyValues(): void {
        const controlsKeys = Object.keys(this.formGroup.controls);
        const firstExistingValueKey = controlsKeys.find(k => !!this.formGroup.get(k)!.value);

        if (!firstExistingValueKey) {
            this.alertService.showAlert('Components.TranslationDialog.AllFieldsAreEmpty');
            return;
        }

        this.isTranslating.set(true);

        const controlsWithNoValueKeys = controlsKeys.filter(k => !this.formGroup.get(k)!.value);
        const sourceValue = this.formGroup.get(firstExistingValueKey)!.value;
        const sourceLanguage = firstExistingValueKey.slice(-2).toLowerCase();
        const requests: Observable<ApiResponseModel<string>>[] = [];

        for (const controlName of controlsWithNoValueKeys) {
            const control = this.formGroup.get(controlName)!;
            const targetLanguage = controlName.slice(-2).toLowerCase();

            requests.push(
                this.generalDataStore.translate(sourceValue, targetLanguage, sourceLanguage).pipe(
                    tap(resp => {
                        if (!!resp?.value) {
                            control.patchValue(resp.value);
                        }
                    })
                )
            );
        }

        forkJoin(requests)
            .pipe(finalize(() => this.isTranslating.set(false)))
            .subscribe();
    }
}
