@if(!ticket()) {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="32"></mat-spinner>
    </div>
} @else {
    <div class="flex flex-col gap-4 grow overflow-y-auto">
        @if(messagesOrEvents().length > 0) {
            <div class="flex flex-col gap-4 p-2">
                @for(messageOrEvent of messagesOrEvents(); track messageOrEvent.id) {
                    @if(messageOrEvent.type === TicketMessageTypeEnum.Message) {
                        <arc-ticket-message-item [message]="messageOrEvent.item | transform : asMessage"></arc-ticket-message-item>
                    } @else if (messageOrEvent.type === TicketMessageTypeEnum.Event) {
                        <arc-event-message-item [event]="messageOrEvent.item | transform : asEvent"></arc-event-message-item>
                    }
                }
            </div>
        }
        <div class="flex justify-between gap-2">
            <div class="flex">
                <arc-checkbox labelKey="Tickets.Edit.Private" [control]="formGroup.controls.private"></arc-checkbox>
                <arc-checkbox labelKey="Tickets.Edit.Escalated" [control]="formGroup.controls.escalated"></arc-checkbox>
            </div>
            <arc-button-toggle-group [control]="typeControl" [items]="messageTypes"></arc-button-toggle-group>
        </div>
        @switch (typeControl.value) {
            @case (MessageContentTypeEnum.Story) {
                <div class="justify-items-end">
                    <div>
                        <arc-number-input [formControl]="workItemIdControl" [decimalPlaces]="0" label="Tickets.Edit.WorkItemId">
                        </arc-number-input>
                    </div>
                </div>
            }
            @case (MessageContentTypeEnum.HelpArticle) {
                <div class="justify-items-end">
                    <div class="w-2/3">
                        <mat-form-field class="w-full">
                            <mat-label>{{'Tickets.Edit.HelpArticle' | transloco}}</mat-label>
                            <input type="text" matInput [formControl]="helpArticleControl" [matAutocomplete]="auto">
                            <mat-autocomplete #auto="matAutocomplete" [displayWith]="optionDisplayFn">
                                @for (option of filteredPublicHelpArticles | async; track option) {
                                    <mat-option [value]="option" class="w-full">
                                        <div class="flex justify-between">
                                            <div class="flex-1">
                                                <span>{{option.title}}</span> <br>
                                                <small>{{option.summary}}</small>
                                            </div>
                                            <div class="ml-2 flex items-center">
                                                <div class="inline-flex justify-center items-center py-1 px-2 rounded text-sm"
                                                    [style.height.px]="26" [ngClass]="getRequiredRoleBgColor(option.requiredRole)">
                                                    @if (!!option.requiredRole) {
                                                        {{getRequiredRole(option.requiredRole)}}
                                                    } @else {
                                                        {{'General.All' | transloco}}
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </mat-option>
                                }
                            </mat-autocomplete>
                        </mat-form-field>
                    </div>
                </div>
            }
        }
        <arc-markdown-editor [formControl]="formGroup.controls.text" styleClass="mde-small"> </arc-markdown-editor>
        <arc-file-upload class="block w-full !h-[60px] px-[2px] mb-1" [shouldForceMinHeight]="false" [formControl]="fileControl"
            [allowUrlUpload]="false" [maxFileSizeInKb]="5120">
        </arc-file-upload>
        @for(file of formGroup.value.attachments; track file.fileName) {
            <div class="flex items-center p-4 bg-app rounded-lg">
                <mat-icon>insert_drive_file</mat-icon>
                <span class="ml-2">{{ file.fileName }}</span>
                <button mat-icon-button class="ml-auto" (click)="removeFile(file.blobDataId)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        }
    </div>
    <div class="flex flex-col gap-4 sticky bottom-0 z-[200] bg-element">
        <div class="flex items-center gap-4 mt-4 py-8 smd:py-4 shadow-[rgba(0,0,15,0.5)_1px_-6px_9px_-11px] z-[200]">
            <button mat-stroked-button (click)="cancel()">
                {{ 'General.Actions.Cancel' | transloco }}
            </button>
            <div class="grow"></div>

            @if (!!visibleSecondaryAction) {
                <button mat-stroked-button [matTooltip]="getButtonText(visibleSecondaryAction)" matTooltipPosition="left"
                    [disabled]="isButtonDisabled(visibleSecondaryAction)" (click)="onContextActionClick($event,visibleSecondaryAction)">
                    <div class="flex gap-2">
                        @if (visibleSecondaryAction.isLoading) {
                            <mat-spinner diameter="22"></mat-spinner>
                        } @else {
                            <i class="material-icons">{{ visibleSecondaryAction.icon }}</i>
                        }
                        <span>{{ getButtonText(visibleSecondaryAction) }}</span>
                    </div>
                </button>
            }

            <button mat-flat-button color="accent" [matTooltip]="getButtonText(visiblePrimaryAction)" matTooltipPosition="left"
                [disabled]="isButtonDisabled(visiblePrimaryAction)" (click)="onContextActionClick($event, visiblePrimaryAction)">
                <div class="flex gap-2">
                    @if (visiblePrimaryAction.isLoading) {
                        <mat-spinner diameter="22"></mat-spinner>
                    } @else {
                        <i class="material-icons">{{ visiblePrimaryAction.icon }}</i>
                    }
                    <span>{{ getButtonText(visiblePrimaryAction) }}</span>
                </div>
            </button>

            @if (collapsedActions.length > 0) {
                <button mat-icon-button [matMenuTriggerFor]="contextActionsMenu">
                    <mat-icon>more_horiz</mat-icon>
                </button>
                <mat-menu #contextActionsMenu="matMenu">
                    @for (btn of collapsedActions; track btn.key) {
                        <button mat-menu-item [matTooltip]="getButtonText(btn)" matTooltipPosition="left" [disabled]="isButtonDisabled(btn)"
                            (click)="onContextActionClick($event, btn)">
                            <div class="flex gap-2">
                                @if (btn.isLoading) {
                                    <mat-spinner diameter="22"></mat-spinner>
                                } @else {
                                    <i class="material-icons">{{ btn.icon }}</i>
                                }
                                <span>{{ getButtonText(btn) }}</span>
                            </div>
                        </button>
                    }
                </mat-menu>
            }
        </div>
    </div>
}