import { Component, computed, inject, input } from '@angular/core';

import { Utils } from '../../../../../../core/utils/tools/utils.tools';
import { BlobModel } from '../../../../../models/blob.model';
import { TicketMessageModel } from '../../../../../models/ticket-message.model';
import { SecurityStorage } from '../../../../../../core/services/storages/security.storage';
import { TranslationService } from '../../../../../../core/services/translation.service';
import { Tools } from '../../../../../../core/utils/tools';

@Component({
    selector: 'arc-ticket-message-item',
    templateUrl: './ticket-message-item.component.html',
    styleUrl: './ticket-message-item.component.scss'
})
export class TicketMessageItemComponent {
    message = input.required<TicketMessageModel>();
    createdBy = computed(() => {
        const myEmail = this.securityStorage.getUserInfo()?.email;
        return this.message().isMyResponse && this.message().createdByEmail === myEmail
            ? this.translationService.getText('Tickets.Edit.Me')
            : this.message().createdByEmail;
    });
    shouldUseWhiteFont = computed(() => Tools.Utils.hexColorShouldUseWhiteFont(this.message().backgroundColorHex));
    messageClasses = computed(() => {
        const message = this.message();
        const shouldUseWhiteFont = this.shouldUseWhiteFont();

        if (!!message.backgroundColorHex) {
            return shouldUseWhiteFont ? 'text-white' : 'text-black';
        }

        return message.isMyResponse ? 'bg-success-light text-on-success-light' : 'bg-element-selected text-on-app';
    });

    private readonly securityStorage = inject(SecurityStorage);
    private readonly translationService = inject(TranslationService);

    downloadFile(file: BlobModel): void {
        const blob = new Blob([new Uint8Array(file.blobData)], { type: file.fileMimeType });
        Utils.saveFile(blob, file.fileName ?? 'file.bin');
    }

    openHelpArticle(helpArticleId: number): void {
        window.open(`${window.location.protocol}//${window.location.host}/support/help?Id=${helpArticleId}`, '_blank');
    }
}
