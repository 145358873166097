import { Component, OnInit, WritableSignal, computed, inject, input, output, signal } from '@angular/core';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';

import { EditSidebarComponentModel } from '../models/edit-sidebar-component.model';
import { SharedDataService } from '../../../../core/services/shared-data.service';
import { OptionalType } from '../../../../core/models/types/optional.type';
import { BaseEditSidebarItemComponent } from '../base-edit-item/base-edit-sidebar-item.component';
import { Identifyable } from '../../../../core/abstractions/identifyable';

@Component({
    selector: 'arc-edit-sidebar-item',
    templateUrl: './edit-sidebar-item.component.html',
    styleUrl: './edit-sidebar-item.component.scss'
})
export class EditSidebarItemComponent<T extends Identifyable<TId>, TUpdate extends Identifyable<TId> = T, TId = number> implements OnInit {
    readonly shouldStartExpanded = input.required<boolean>();
    readonly editComponent = input.required<EditSidebarComponentModel<T, TUpdate, TId>>();
    readonly componentPortal = input<ComponentPortal<BaseEditSidebarItemComponent<T, TUpdate, TId>>>();
    readonly componentAttached = output<CdkPortalOutletAttachedRef>();
    readonly expandedChanged = output<boolean>();

    isExpanded = signal(true);
    isCurrentlyExpanded = computed(() => this.isExpanded() || this.shouldStartExpanded());
    isHidden: WritableSignal<OptionalType<boolean>> = signal(false);

    private readonly sharedDataService = inject(SharedDataService);

    ngOnInit(): void {
        const editComponent = this.editComponent();

        if (!!editComponent.shouldHideSignalName) {
            this.isHidden = this.sharedDataService.getOrCreateSignal<boolean>(editComponent.shouldHideSignalName);
        }
    }

    setExpanded(isExpanded: boolean): void {
        this.expandedChanged.emit(isExpanded);
        this.isExpanded.set(isExpanded);
    }
}
