import { Component, inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { PageListViewComponent } from '../../../../components/list-views/page-list-view/page-list-view.component';
import { CustomerInteractionListModel } from '../../../models/responses/customer-interaction-list.model';
import { CustomerInteractionModel } from '../../../models/customer-interaction.model';
import { CustomerInteractionsStore } from '../../../services/stores/customer-interactions.store';
import { TableListViewConfigModel } from '../../../../components/list-views/table-list-view/models/table-list-view-config.model';
import { StackedColumnModel } from '../../../../components/dynamic-table/models/column-types/stacked-column.model';
import { StringColumnModel } from '../../../../components/dynamic-table/models/column-types/string-column.model';
import { SortDirectionEnum } from '../../../../core/models/enums/sort-direction.enum';
import { ColumnSortModel } from '../../../../core/models/column-sort.model';
import { CustomerInteractionDialogComponent } from '../../../../components/dialogs/customer-interaction-dialog/customer-interaction-dialog.component';
import { TableListDetailWidgetModel } from '../../../../components/list-views/table-list-view/models/table-list-detail-widget.model';
import { CustomerInteractionsNoteDetailWidget } from './customer-interactions-widgets/customer-interactions-note-widget/customer-interactions-note.detail-widget';
import { ButtonsVisibilityModel } from '../../../../core/models/buttons-visibility.model';
import { GenericStatusColumnModel } from '../../../../components/dynamic-table/models/column-types/generic-status-column.model';
import { CustomerInteractionMoodsEnumExtensions } from '../../../models/enums/customer-interaction-moods.enum';
import { DateService } from '../../../../core/services/date.service';

@Component({
    selector: 'arc-customer-interactions',
    templateUrl: '../../../../components/list-views/page-list-view/page-list-view.component.html',
    styleUrl: './customer-interactions.component.scss'
})
export class CustomerInteractionsComponent extends PageListViewComponent<CustomerInteractionListModel, CustomerInteractionModel> {
    private readonly customerInteractionsStore = inject(CustomerInteractionsStore);
    private readonly matDialog = inject(MatDialog);
    private readonly dateService = inject(DateService);
    constructor() {
        super();
        this.config = new TableListViewConfigModel<CustomerInteractionListModel, CustomerInteractionModel>({
            entityName: 'CustomerInteraction',
            availableColumns: {
                createdDate: new StackedColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.CreatedDate',
                    propertyName: 'createdDate',
                    propertyName2: 'createdByUser',
                    customFormatter: (record, value, propertyName) => {
                        if (propertyName === 'createdDate' && value) {
                            return this.dateService.format(value, 'short');
                        }
                        return value ?? ''; // Default return for other cases
                    },
                    widthPixels: 180
                }),
                license: new StackedColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.License',
                    propertyName: 'licenseHolder',
                    propertyName2: 'licenseId',
                    widthPixels: 180
                }),
                userName: new StackedColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.UserName',
                    propertyName: 'userName',
                    propertyName2: 'userEmail',
                    widthPixels: 250
                }),
                userPhone: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.UserPhone',
                    propertyName: 'userPhone',
                    widthPixels: 120
                }),
                type: new GenericStatusColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.Type',
                    propertyName: 'mood',
                    statusColumn: 'mood',
                    descriptionColumn: 'typeDescription',
                    widthPixels: 120,
                    bgColor: value => CustomerInteractionMoodsEnumExtensions.getColor(value)
                }),
                message: new StringColumnModel({
                    columnTitleKey: 'CustomerInteraction.List.Message',
                    propertyName: 'message'
                })
            },
            defaultColumnOrder: ['createdDate', 'license', 'userName', 'userPhone', 'type', 'message'],
            defaultSort: new ColumnSortModel({
                column: 'createdDate',
                direction: SortDirectionEnum.Desc
            }),
            availableDetailWidgets: {
                message: new TableListDetailWidgetModel({
                    component: CustomerInteractionsNoteDetailWidget,
                    shouldHideHeader: true,
                    takeFullSizeIfOnly: true
                })
            },
            defaultDetailWidgetOrder: ['message'],
            editSidebarConfig: {
                editComponents: []
            },
            handleCreateButtonClick: this.createCustomerInteraction.bind(this),
            buttonsVisibility: new ButtonsVisibilityModel({ hasEdit: false }),
            store: this.customerInteractionsStore
        });
    }

    createCustomerInteraction(): Observable<boolean> {
        return this.matDialog
            .open(CustomerInteractionDialogComponent, {
                width: '600px',
                maxHeight: '98svh',
                maxWidth: '98vw'
            })
            .afterClosed()
            .pipe(map(() => true));
    }
}
