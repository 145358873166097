<mat-expansion-panel *ngIf="!isHidden()" cdkDrag cdkDragLockAxis="y" (expandedChange)="setExpanded($event)"
    [expanded]="isCurrentlyExpanded()">
    <mat-expansion-panel-header>
        <mat-panel-title class="gap-4">
            <mat-icon class="cursor-move text-on-app-light" cdkDragHandle>drag_handle</mat-icon>
            <span class="grow">{{ editComponent().titleKey | transloco }}</span>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template [cdkPortalOutlet]="componentPortal()" (attached)="componentAttached.emit($event)">
    </ng-template>
</mat-expansion-panel>