<div class="flex flex-col" [ngClass]="message().isMyResponse ? 'items-end ml-12' : 'items-start mr-12'">
    <span class="float-right text-xs text-element-disabled" [class.mr-1]="message().isMyResponse" [class.ml-1]="!message().isMyResponse">
        {{ createdBy() }} {{'Tickets.Edit.On' | transloco}} {{ message().createdDate | arcDate: 'short' }}
    </span>
    <div class="p-4 rounded-lg" [style.background-color]="message().backgroundColorHex" [ngClass]="messageClasses()">
        <div class="arc-markdown" markdown>{{ message().text }}</div>
    </div>

    @if(!!message().devOpsItemId) {
        <div class="p-4 rounded-lg my-2 shadow bg-white">
            <div class="text-xs">{{'Tickets.DevOpsInfo' | transloco}}</div>
            <div class="flex items-center gap-2">
                <mat-icon>{{message().devOpsItemIcon}}</mat-icon>
                <span>#{{ message().devOpsItemId }}</span>
                <mat-icon [style.color]="'#' + message().devOpsItemStatusColor">circle</mat-icon>
                <span>{{ message().devOpsItemStatusDescription }}</span>
            </div>
        </div>
    }

    @if(!!message().helpArticleId) {
        <div class="p-4 rounded-lg my-2 shadow bg-white cursor-pointer" (click)="openHelpArticle(message().helpArticleId!)">
            <div class="flex items-center gap-2">
                <mat-icon>help_outline</mat-icon>
                <span class="mr-4">{{ message().helpArticleTitle }}</span>
                <mat-icon>chevron_right</mat-icon>
            </div>
        </div>
    }

    @for(attachment of message().attachments; track attachment.id){
        <div class="p-4 rounded-lg my-4" [style.background-color]="message().backgroundColorHex" [ngClass]="messageClasses()">
            <div class="flex items-center gap-2">
                <mat-icon class="mr-2">insert_drive_file</mat-icon>
                <span>{{ attachment.fileName }}</span>
                <button class="ml-auto" mat-icon-button (click)="downloadFile(attachment)">
                    <mat-icon>download</mat-icon>
                </button>
            </div>
        </div>
    }
</div>