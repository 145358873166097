import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GridsterConfig, GridType, DisplayGrid, GridsterItem } from 'angular-gridster2';

import { WidgetModel } from '../models/widget.model';
import { DashboardConfigurationModel } from '../models/dashboard-configuration.model';
import { Tools } from '../utils/tools';
import { DictionaryType } from '../models/types/dictionary.type';
import { StatisticsWidgetModel } from '../models/statistics-widget.model';
import { TranslationService } from './translation.service';
import { WidgetTypeEnum } from '../models/enums/widget-type.enum';
import { LayoutService } from './layout.service';
import { UserRoles } from '../../app/models/enums/user-roles.enum';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    gridsterOptions: GridsterConfig;
    onWidgetAdded = new Subject<WidgetModel>();
    onWidgetRemoved = new Subject<WidgetModel>();
    widgets = new BehaviorSubject<WidgetModel[]>([]);
    get isDashboardEditable$(): Observable<boolean> {
        return this._isDashboardEditable$;
    }
    set isDashboardEditable$(value) {
        this._isDashboardEditable$ = value;
    }

    readonly widgetsList: DictionaryType<WidgetModel> = {};

    private _widgets: WidgetModel[] = [];
    private isDashboardEditableSubject = new BehaviorSubject<boolean>(false);
    private _isDashboardEditable$ = this.isDashboardEditableSubject.asObservable();
    private readonly layoutService = inject(LayoutService);
    private readonly translationService = inject(TranslationService);
    private readonly authService = inject(AuthService);

    constructor() {
        this.gridsterOptions = {
            gridType: GridType.Fixed,
            displayGrid: DisplayGrid.None,
            enableEmptyCellContextMenu: true,
            fixedRowHeight: 50,
            fixedColWidth: 50,
            setGridSize: false,
            minCols: 10,
            minRows: 10,
            maxCols: 100,
            maxRows: 100,
            pushItems: true,
            outerMargin: true,
            margin: 7
        };
        this.initializeWidgetsList();
    }
    /**
     * Call this method from a consuming class to initialize the service.
     * */
    init(): void {
        this.loadInitial();
    }

    addWidgetByType(componentType: WidgetTypeEnum, position?: GridsterItem): void {
        // when adding new widgets, call the constructor and pass a guid
        // const widget = new WidgetModel(this.widgetsList[componentType], Tools.Utils.newGuid());
        const model = this.getConcreteModelForWidgetType(this.widgetsList[componentType], Tools.Utils.newGuid());
        model.title = this.translationService.getText(`Widgets.WidgetTypes.${componentType}`);

        this.addWidget(model, position);
    }

    addWidget(widget: WidgetModel, position?: GridsterItem): void {
        if (!!position) {
            widget.defaultStartX = position.x;
            widget.defaultStartY = position.y;
        }

        this._widgets.push(widget);
        this.onWidgetAdded.next(widget);
        this.widgets.next(this._widgets);
    }

    removeWidget(widget: WidgetModel): void {
        const idx = this._widgets.findIndex(c => c.id === widget.id);

        this._widgets.splice(idx, 1);
        this.onWidgetRemoved.next(widget);
        this.widgets.next(this._widgets);
    }

    saveCurrent(): void {
        const widgets: WidgetModel[] = [];

        this._widgets.forEach(lc => {
            const nWidget = Object.assign({}, lc);
            widgets.push(nWidget);
        });

        this.layoutService.saveDashboardConfiguration(
            new DashboardConfigurationModel({
                widgets: widgets
            })
        );
    }

    clearDashboard(): void {
        this._widgets = [];
        this.widgets.next(this._widgets);
    }

    setDashboardEditable(isEditable: boolean): void {
        this.isDashboardEditableSubject.next(isEditable);
    }

    private loadInitial(): void {
        this.layoutService.getDashboardConfiguration().subscribe(dSettings => this.handleDashboardConfig(dSettings));
    }

    private handleDashboardConfig(dashboardConfig: DashboardConfigurationModel): void {
        const widgets: WidgetModel[] = [];

        dashboardConfig.widgets.forEach((model: WidgetModel) => {
            if (!!this.widgetsList[model.type]) {
                widgets.push(this.getConcreteModelForWidgetType(model));
            }
        });
        this._widgets = widgets;

        this.widgets.next(this._widgets);
    }

    private getConcreteModelForWidgetType(model: WidgetModel, id?: string): WidgetModel {
        if (model.type === WidgetTypeEnum.LicensesByProduct) {
            model = new StatisticsWidgetModel(model, id);
        } else if (model.type === WidgetTypeEnum.TransactionsByReseller) {
            model = new StatisticsWidgetModel(model, id);
        } else {
            model = new WidgetModel(model, id);
        }
        return model;
    }

    private initializeWidgetsList(): void {
        if (this.authService.hasRole(UserRoles.Licenses)) {
            this.widgetsList[WidgetTypeEnum.LicensesByProduct] = new WidgetModel({
                type: WidgetTypeEnum.LicensesByProduct,
                defaultColumns: 5,
                defaultRows: 5,
                minColumns: 1,
                minRows: 1
            });
            this.widgetsList[WidgetTypeEnum.TransactionsByReseller] = new WidgetModel({
                type: WidgetTypeEnum.TransactionsByReseller,
                defaultColumns: 5,
                defaultRows: 5,
                minColumns: 1,
                minRows: 1
            });
            if (this.authService.hasRole(UserRoles.Admin) || this.authService.hasRole(UserRoles.Dashboard)) {
                this.widgetsList[WidgetTypeEnum.Summarry] = new WidgetModel({
                    type: WidgetTypeEnum.Summarry,
                    defaultColumns: 14,
                    defaultRows: 8,
                    minColumns: 14,
                    minRows: 7
                });
            }
        }
    }
}
