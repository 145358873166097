import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CustomValidators } from '../../../core/utils/custom-validators';
import { ComplexDataTypesEnum } from '../../../core/models/complex-data-types.enum';
import { OptionalType } from '../../../core/models/types/optional.type';
import { ArcFormControl } from '../../../core/utils/arc-form-control';
import { CustomerInteractionMoodsEnum } from '../../../app/models/enums/customer-interaction-moods.enum';
import { CustomerInteractionTypesEnum } from '../../../app/models/enums/customer-interaction-types.enum';
import { CustomerInteractionsStore } from '../../../app/services/stores/customer-interactions.store';
import { ToasterService } from '../../../core/services/toaster.service';
import { TranslationService } from '../../../core/services/translation.service';
import { BrokenRuleModel } from '../../../app/models/broken-rule.model';
import { ErrorsService } from '../../../core/services/errors.service';
import { BaseComponent } from '../../abstractions/base.component';
import { LicensesStore } from '../../../app/services/stores/licenses.store';
import { LicenseInteractionModel } from '../../../app/models/license-interaction.model';

@Component({
    selector: 'arc-customer-interaction-dialog',
    templateUrl: './customer-interaction-dialog.component.html',
    styleUrl: './customer-interaction-dialog.component.scss'
})
export class CustomerInteractionDialogComponent extends BaseComponent implements OnInit, OnDestroy {
    formGroup = new FormGroup(
        {
            licenseId: new ArcFormControl<OptionalType<number>>(undefined),
            userId: new ArcFormControl<OptionalType<number>>(undefined),
            message: new ArcFormControl<OptionalType<string>>(undefined, Validators.required),
            type: new ArcFormControl<OptionalType<CustomerInteractionTypesEnum>>(CustomerInteractionTypesEnum.Phone, Validators.required),
            mood: new ArcFormControl<OptionalType<CustomerInteractionMoodsEnum>>(CustomerInteractionMoodsEnum.Good, Validators.required)
        },
        {
            validators: CustomValidators.atLeastOneFieldValidator(['licenseId', 'userId'])
        }
    );

    isCreating = signal(false);
    canCreate = signal(true);

    readonly data: LicenseInteractionModel = inject(MAT_DIALOG_DATA);
    readonly translationService = inject(TranslationService);
    readonly customerInteractionTypeItems = [
        { value: CustomerInteractionTypesEnum.Phone, label: this.translationService.getText('CustomerInteraction.Types.Phone') },
        { value: CustomerInteractionTypesEnum.Email, label: this.translationService.getText('CustomerInteraction.Types.Email') },
        { value: CustomerInteractionTypesEnum.Teams, label: this.translationService.getText('CustomerInteraction.Types.Teams') },
        { value: CustomerInteractionTypesEnum.InPerson, label: this.translationService.getText('CustomerInteraction.Types.InPerson') }
    ];

    readonly customerInteractionMoodItems = [
        { value: CustomerInteractionMoodsEnum.Good, label: this.translationService.getText('CustomerInteraction.Moods.Good') },
        { value: CustomerInteractionMoodsEnum.Bad, label: this.translationService.getText('CustomerInteraction.Moods.Bad') }
    ];

    ComplexDataTypesEnum = ComplexDataTypesEnum;

    private readonly customerInteractionsStore = inject(CustomerInteractionsStore);
    private readonly matDialogRef = inject(MatDialogRef);
    private readonly toasterService = inject(ToasterService);
    private readonly errorsService = inject(ErrorsService);
    private readonly licensesStore = inject(LicensesStore);

    ngOnInit(): void {
        if (!!this.data) {
            this.canCreate.set(false);
            this.customerInteractionsStore.get(this.data.id).subscribe(result => {
                if (result.value) {
                    this.formGroup.patchValue(result.value);
                }
            });
            this.formGroup.disable();
        }

        this.errorsService.shouldDisplayAlertOnError = false;
        this.addSubscriptions(
            this.errorsService.onBusinessException.subscribe(response => {
                this.setBusinessErrors(response.brokenRules ?? []);
                if (!!response.message) {
                    this.toasterService.showError(response.message, undefined, true);
                } else {
                    this.toasterService.showError('CustomerInteraction.CreateFailed');
                }
            })
        );

        this.addSubscriptions(
            this.formGroup.controls.licenseId.valueChanges.subscribe(licenseId => {
                if (!licenseId) {
                    return;
                }
                this.handleLicenseUpdate(licenseId);
            }),
            this.formGroup.controls.userId.valueChanges.subscribe(userId => {
                if (!userId) {
                    return;
                }
                this.handleUserUpdate(userId);
            })
        );
    }
    override ngOnDestroy(): void {
        this.errorsService.shouldDisplayAlertOnError = true;
    }

    createCustomerInteraction(): void {
        this.formGroup.markAllAsTouched();
        this.formGroup.updateValueAndValidity();
        if (!this.formGroup.valid) {
            return;
        }

        this.isCreating.set(true);
        const customerInteraction = {
            id: 0,
            licenseId: this.formGroup.value.licenseId ?? undefined,
            userId: this.formGroup.value.userId ?? undefined,
            message: this.formGroup.value.message ?? '',
            type: this.formGroup.value.type!,
            mood: this.formGroup.value.mood!
        };

        this.customerInteractionsStore.add(customerInteraction).subscribe({
            next: response => {
                this.toasterService.showSuccess('CustomerInteraction.CreateSuccess');
                this.matDialogRef.close({ shouldReload: false, id: response.value });
            },
            error: () => {
                this.toasterService.showError('General.Alert.UnexpectedError');
            },
            complete: () => {
                this.isCreating.set(false);
            }
        });
    }

    setBusinessErrors(brokenRules: BrokenRuleModel[]): void {
        if (!this.formGroup) {
            return;
        }

        for (const brokenRule of brokenRules) {
            if (!brokenRule.property) {
                continue;
            }

            const controlName = brokenRule.property.charAt(0).toLowerCase() + brokenRule.property.slice(1);
            const control = this.formGroup.get(controlName);

            if (!control) {
                continue;
            }

            control.setErrors({ businessError: brokenRule.message });
        }
    }

    handleLicenseUpdate(licenseId: number): void {
        this.licensesStore.get(licenseId).subscribe({
            next: result => {
                if (result.value?.userId !== this.formGroup.value.userId) {
                    this.formGroup.controls.userId.setValue(result.value?.userId, { emitEvent: false });
                }
            }
        });
    }

    handleUserUpdate(userId: number): void {
        this.licensesStore.getLicenseByUserId(userId).subscribe({
            next: result => {
                if (result.value?.id !== this.formGroup.value.licenseId) {
                    this.formGroup.controls.licenseId.setValue(result.value?.id, { emitEvent: false });
                }
            }
        });
    }
}
