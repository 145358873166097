import { Component, inject, signal } from '@angular/core';

import { StatisticsStore } from '../../../app/services/stores/statistics.store';
import { ResellerSummaryModel } from '../../../app/models/reseller-summary.model';
import { OptionalType } from '../../../core/models/types/optional.type';

@Component({
    selector: 'arc-reseller-statistics-widget',
    templateUrl: './reseller-statistics-widget.component.html',
    styleUrl: './reseller-statistics-widget.component.scss'
})
export class ResellerStatisticsWidgetComponent {
    readonly resellerSummary = signal<OptionalType<ResellerSummaryModel>>(undefined);
    private readonly statisticsStore = inject(StatisticsStore);

    constructor() {
        this.statisticsStore.summary().subscribe(result => {
            if (!!result.value) {
                this.resellerSummary.set(result.value);
            }
        });
    }

    getPercentageColor(value: number): string {
        switch (true) {
            case value > 0:
                return 'text-success'; // Green
            case value < 0:
                return 'text-error'; // Red
            default:
                return 'text-black'; // Black
        }
    }
}
