@if (resellerSummary(); as resellerSummary){
    <div class="flex flex-col gap-4 p-8">
        <h2 class="font-semibold self-start">{{'Components.ResellerStatisticsWidget.Title' | transloco}}</h2>
        <div class="flex justify-around w-full items-center">
            <div
                class="grid grid-cols-[repeat(3,_auto)] gap-x-8 gap-y-4 mb-4 bg-light-gray dark:bg-dark-gray px-8 py-4 rounded justify-center">
                <div>
                    <span class="text-md">{{'Components.ResellerStatisticsWidget.Licenses' | transloco}}</span>
                </div>
                <div>
                    <span class="text-md">{{'Components.ResellerStatisticsWidget.NewYTD' | transloco}}</span>
                </div>
                <div></div>
                <div>
                    <span class="text-2xl">{{resellerSummary.totalLicenses}}</span>
                </div>
                <div>
                    <span class="text-2xl">{{resellerSummary.newLicensesYTD}}</span>
                </div>
                <div class="flex items-center">
                    <span class="text-lg" [ngClass]="resellerSummary.newLicensesIncreasePercent | transform : getPercentageColor">{{resellerSummary.newLicensesIncreasePercent}}%</span>
                </div>
                <div>
                    <span class="text-md">{{'Components.ResellerStatisticsWidget.NumberofPos' | transloco}}</span>
                </div>
                <div>
                    <span class="text-md">{{'Components.ResellerStatisticsWidget.BilledYTD' | transloco}}</span>
                </div>
                <div></div>
                <div>
                    <span class="text-2xl">{{resellerSummary.totalNumberOfPos}}</span>
                </div>
                <div>
                    <span class="text-2xl">{{resellerSummary.billedYTD | arcCurrency}}</span>
                </div>
                <div class="flex items-center">
                    <span class="text-lg" [ngClass]="resellerSummary.billedIncreasePercent | transform : getPercentageColor">{{resellerSummary.billedIncreasePercent}}%</span>
                </div>
            </div>
            <div class="flex flex-col gap-2 bg-light-gray dark:bg-dark-gray px-8 py-4 rounded">
                <div class="flex flex-col">
                    <span class="text-md">{{'Components.ResellerStatisticsWidget.MonthlyIncome' | transloco}}</span>
                    <span class="text-2xl">{{resellerSummary.monthlyIncome | arcCurrency}}</span>
                </div>
                <div class="flex flex-col">
                    <span class="text-md">{{'Components.ResellerStatisticsWidget.OpenInvoiceAmount' | transloco}}</span>
                    <span class="text-2xl">{{resellerSummary.openInvoiceAmount | arcCurrency}}</span>
                </div>
            </div>
        </div>
        <div class="grid grid-flow-col grid-rows-[repeat(7,_auto)] divide-y divide-primary-400 divide-solid">
            <div></div>
            <div class="flex items-center p-2">
                <span class="text-sm">{{'Components.ResellerStatisticsWidget.LicensesOld' | transloco}}</span>
            </div>
            <div class="flex items-center p-2">
                <span class="text-sm">{{'Components.ResellerStatisticsWidget.NumberofPos' | transloco}}</span>
            </div>
            <div class="flex items-center p-2">
                <span class="text-sm">{{'Components.ResellerStatisticsWidget.NewYTD' | transloco}}</span>
            </div>
            <div class="flex items-center p-2">
                <span class="text-sm">{{'Components.ResellerStatisticsWidget.LicenseYTD' | transloco}}</span>
            </div>
            <div class="flex items-center p-2">
                <span class="text-sm">{{'Components.ResellerStatisticsWidget.IndividualYTD' | transloco}}</span>
            </div>
            <div class="flex items-center p-2">
                <span class="text-sm">{{'Components.ResellerStatisticsWidget.HardwareYTD' | transloco}}</span>
            </div>
            @for (detail of resellerSummary.details; track $index) {
                <div class="p-2 !border-t-0">
                    <span class="text-sm font-bold">{{detail.name}}</span>
                </div>
                <div class="p-2">
                    <span class="text-sm">{{detail.totalLicenses}} ({{detail.remainingLegacyLicenses}})</span>
                </div>
                <div class="p-2">
                    <span class="text-sm">{{detail.totalNumberOfPos}}</span>
                </div>
                <div class="flex flex-col p-2">
                    <span class="text-xs" [ngClass]="detail.newLicensesIncreasePercent | transform : getPercentageColor">{{detail.newLicensesIncreasePercent}}%</span>
                    <span class="text-sm">{{detail.newLicensesYTD}}</span>
                </div>
                <div class="flex flex-col p-2">
                    <span class="text-xs" [ngClass]="detail.billedLicensesIncreasePercent | transform : getPercentageColor">{{detail.billedLicensesIncreasePercent}}%</span>
                    <span class="text-sm">{{detail.billedLicensesYTD | arcCurrency}}</span>
                </div>
                <div class="flex flex-col p-2">
                    <span class="text-xs" [ngClass]="detail.billedIndividualIncreasePercent | transform : getPercentageColor">{{detail.billedIndividualIncreasePercent}}%</span>
                    <span class="text-sm">{{detail.billedIndividualYTD | arcCurrency}}</span>
                </div>
                <div class="flex flex-col p-2">
                    <span class="text-xs" [ngClass]="detail.billedHardwareIncreasePercent | transform : getPercentageColor">{{detail.billedHardwareIncreasePercent}}%</span>
                    <span class="text-sm">{{detail.billedHardwareYTD | arcCurrency}}</span>
                </div>
                }
        </div>
    </div>

} @else {
    <div class="w-full h-full grid place-items-center">
        <mat-spinner [diameter]="64"></mat-spinner>
    </div>
}
