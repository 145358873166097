import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { QuillModule } from 'ngx-quill';
import { MarkdownModule } from 'ngx-markdown';
import { FlatpickrModule } from 'angularx-flatpickr';

import { InputComponent } from './form/input/input.component';
import { MaterialModule } from '../core/utils/material.module';
import { FormFieldErrorControlComponent } from './form/form-field-error-control/form-field-error-control.component';
import { ChartComponent } from './chart/chart.component';
import { TableListViewComponent } from './list-views/table-list-view/table-list-view.component';
import { CardListViewComponent } from './list-views/card-list-view/card-list-view.component';
import { ButtonColumnComponent } from './dynamic-table/column-components/button-column/button-column.component';
import { ButtonToggleColumnComponent } from './dynamic-table/column-components/button-toggle-column/button-toggle-column.component';
import { CheckboxColumnComponent } from './dynamic-table/column-components/checkbox-column/checkbox-column.component';
import { ColoredStackedColumnComponent } from './dynamic-table/column-components/colored-stacked-column/colored-stacked-column.component';
import { CurrencyColumnComponent } from './dynamic-table/column-components/currency-column/currency-column.component';
import { CustomColumnComponent } from './dynamic-table/column-components/custom-column/custom-column.component';
import { DateColumnComponent } from './dynamic-table/column-components/date-column/date-column.component';
import { GeneralDataColumnComponent } from './dynamic-table/column-components/general-data-column/general-data-column.component';
import { GenericStatusColumnComponent } from './dynamic-table/column-components/generic-status-column/generic-status-column.component';
import { IconColumnComponent } from './dynamic-table/column-components/icon-column/icon-column.component';
import { NumberColumnComponent } from './dynamic-table/column-components/number-column/number-column.component';
import { QuickSearchColumnComponent } from './dynamic-table/column-components/quick-search-column/quick-search-column.component';
import { StackedColumnComponent } from './dynamic-table/column-components/stacked-column/stacked-column.component';
import { StringColumnComponent } from './dynamic-table/column-components/string-column/string-column.component';
import { TranslationStringColumnComponent } from './dynamic-table/column-components/translation-string-column/translation-string-column.component';
import { DirectivesModule } from '../core/directives/directives.module';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { FilterComponent } from './search-filter/filter/filter.component';
import { FilterPartComponent } from './search-filter/filter/filter-part/filter-part.component';
import { EditSidebarComponent } from './sidebar-components/edit-sidebar/edit-sidebar.component';
import { PipesModule } from '../core/pipes/pipes.module';
import { SaveFilterDialogComponent } from './search-filter/filter/save-filter-dialog/save-filter-dialog.component';
import { DatepickerComponent } from './form/datepicker/datepicker.component';
import { AutocompleteComponent } from './form/autocomplete/autocomplete.component';
import { SelectComponent } from './form/select/select.component';
import { CheckboxComponent } from './form/checkbox/checkbox.component';
import { DateRangePickerComponent } from './form/date-range-picker/date-range-picker.component';
import { FileUploadComponent } from './form/file-upload/file-upload.component';
import { IconComponent } from './icon/icon.component';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { GeneralPromptDialogComponent } from './dialogs/general-prompt-dialog/general-prompt-dialog.component';
import { FabComponent } from './fab/fab.component';
import { DynamicFormDialogComponent } from './dynamic-form-dialog/dynamic-form-dialog.component';
import { ButtonsBarComponent } from './buttons-bar/buttons-bar.component';
import { TableSettingsDialogComponent } from './list-views/table-list-view/table-settings-dialog/table-settings-dialog.component';
import { GeneralDataSelectComponent } from './form/general-data-select/general-data-select.component';
import { PlaceSelectComponent } from './form/place-select/place-select.component';
import { StaticTableComponent } from './list-views/static-table/static-table.component';
import { RichTextEditorComponent } from './rich-text-editor/rich-text-editor.component';
import { TextareaComponent } from './form/textarea/textarea.component';
import { RichTextEditorFormComponent } from './form/rich-text-editor-form/rich-text-editor-form.component';
import { CarouselComponent } from './carousel/carousel.component';
import { AddressPickerComponent } from './form/address-picker/address-picker.component';
import { TreeDataSelectionDialogComponent } from './dialogs/tree-data-selection-dialog/tree-data-selection-dialog.component';
import { TreeAutocompleteComponent } from './form/tree-autocomplete/tree-autocomplete.component';
import { CustomerDataComponent } from './widgets/shared-detail-widgets/customer-data/customer-data.component';
import { ChipSelectorComponent } from './form/chip-selector/chip-selector.component';
import { EditableTableComponent } from './form/editable-table/editable-table.component';
import { DescriptionWidgetComponent } from './widgets/shared-detail-widgets/description-widget/description-widget.component';
import { ButtonComponent } from './button/button.component';
import { TranslationDialogComponent } from './dialogs/translation-dialog/translation-dialog.component';
import { TranslationInputComponent } from './form/translation-input/translation-input.component';
import { NumberInputComponent } from './form/number-input/number-input.component';
import { CurrencyInputComponent } from './form/currency-input/currency-input.component';
import { AddImageFromUrlDialogComponent } from './form/file-upload/add-url-dialog/add-image-from-url-dialog.component';
import { MarkdownEditorComponent } from './markdown-editor/markdown-editor.component';
import { ReportTemplateParametersDialogComponent } from './report-templates/report-template-parameters-dialog/report-template-parameters-dialog.component';
import { PosReportTemplateParametersDialogComponent } from './pos-report-templates/pos-report-template-parameters-dialog/pos-report-template-parameters-dialog.component';
import { QuickSearchComponent } from './form/quick-search/quick-search.component';
import { DataSelectionDialogComponent } from './dialogs/data-selection-dialog/data-selection-dialog.component';
import { ApiIntegrationStatusComponent } from '../app/views/basic-data/api-integrations/components/api-integration-status/api-integration-status.component';
import { ApiIntegrationEditBaseDataComponent } from '../app/views/basic-data/api-integrations/api-integration-edit-items/api-integration-edit-base-data/api-integration-edit-base-data.component';
import { StatisticsWidgetComponent } from './statistics-widget/statistics-widget.component';
import { StatisticsWidgetOptionsDialogComponent } from './statistics-widget/statistics-widget-options-dialog/statistics-widget-options-dialog.component';
import { ContractsAcceptDialogComponent } from './dialogs/contracts-accept-dialog/contracts-accept-dialog.component';
import { EditSidebarItemComponent } from './sidebar-components/edit-sidebar/edit-sidebar-item/edit-sidebar-item.component';
import { ButtonToggleGroupComponent } from './button-toggle-group/button-toggle-group.component';
import { CustomerInteractionDialogComponent } from './dialogs/customer-interaction-dialog/customer-interaction-dialog.component';
import { DatetimePickerComponent } from './form/datetime-picker/datetime-picker.component';
import { SelectionSliderComponent } from './form/selection-slider/selection-slider.component';
import { ResellerStatisticsWidgetComponent } from './widgets/reseller-statistics-widget/reseller-statistics-widget.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        MaterialModule,
        TranslocoModule,
        DirectivesModule,
        PipesModule,
        NgOptimizedImage,
        QuillModule.forRoot(),
        MarkdownModule.forRoot(),
        FlatpickrModule.forRoot()
    ],
    declarations: [
        InputComponent,
        FormFieldErrorControlComponent,
        ChartComponent,
        TableListViewComponent,
        SearchFilterComponent,
        FilterComponent,
        FilterPartComponent,
        CardListViewComponent,
        ButtonColumnComponent,
        ButtonToggleColumnComponent,
        CheckboxColumnComponent,
        ColoredStackedColumnComponent,
        CurrencyColumnComponent,
        CustomColumnComponent,
        DateColumnComponent,
        GeneralDataColumnComponent,
        GenericStatusColumnComponent,
        IconColumnComponent,
        NumberColumnComponent,
        QuickSearchColumnComponent,
        StackedColumnComponent,
        StringColumnComponent,
        TranslationStringColumnComponent,
        EditSidebarComponent,
        FilterComponent,
        SaveFilterDialogComponent,
        DatepickerComponent,
        AutocompleteComponent,
        SelectComponent,
        CheckboxComponent,
        DateRangePickerComponent,
        FileUploadComponent,
        IconComponent,
        DynamicFormComponent,
        GeneralPromptDialogComponent,
        FabComponent,
        DynamicFormDialogComponent,
        ButtonsBarComponent,
        TableSettingsDialogComponent,
        GeneralDataSelectComponent,
        PlaceSelectComponent,
        StaticTableComponent,
        RichTextEditorComponent,
        TextareaComponent,
        RichTextEditorFormComponent,
        CarouselComponent,
        AddressPickerComponent,
        TreeDataSelectionDialogComponent,
        TreeAutocompleteComponent,
        CustomerDataComponent,
        ChipSelectorComponent,
        EditableTableComponent,
        DescriptionWidgetComponent,
        ButtonComponent,
        TranslationDialogComponent,
        TranslationInputComponent,
        NumberInputComponent,
        CurrencyInputComponent,
        AddImageFromUrlDialogComponent,
        MarkdownEditorComponent,
        QuickSearchComponent,
        DataSelectionDialogComponent,
        ReportTemplateParametersDialogComponent,
        PosReportTemplateParametersDialogComponent,
        ApiIntegrationStatusComponent,
        ApiIntegrationEditBaseDataComponent,
        StatisticsWidgetComponent,
        StatisticsWidgetOptionsDialogComponent,
        ContractsAcceptDialogComponent,
        EditSidebarItemComponent,
        ButtonToggleGroupComponent,
        CustomerInteractionDialogComponent,
        DatetimePickerComponent,
        SelectionSliderComponent,
        ResellerStatisticsWidgetComponent
    ],
    exports: [
        InputComponent,
        FormFieldErrorControlComponent,
        TableListViewComponent,
        CardListViewComponent,
        DatepickerComponent,
        AutocompleteComponent,
        SelectComponent,
        CheckboxComponent,
        DateRangePickerComponent,
        FileUploadComponent,
        IconComponent,
        FabComponent,
        DynamicFormDialogComponent,
        ButtonsBarComponent,
        GeneralDataSelectComponent,
        PlaceSelectComponent,
        StaticTableComponent,
        ChartComponent,
        RichTextEditorComponent,
        TextareaComponent,
        RichTextEditorFormComponent,
        CarouselComponent,
        AddressPickerComponent,
        TreeDataSelectionDialogComponent,
        TreeAutocompleteComponent,
        CustomerDataComponent,
        ChipSelectorComponent,
        EditableTableComponent,
        DescriptionWidgetComponent,
        ButtonComponent,
        TranslationInputComponent,
        NumberInputComponent,
        CurrencyInputComponent,
        AddImageFromUrlDialogComponent,
        MarkdownEditorComponent,
        QuickSearchComponent,
        DataSelectionDialogComponent,
        ReportTemplateParametersDialogComponent,
        PosReportTemplateParametersDialogComponent,
        StatisticsWidgetComponent,
        StatisticsWidgetOptionsDialogComponent,
        SearchFilterComponent,
        DynamicFormComponent,
        ButtonToggleGroupComponent,
        SelectionSliderComponent,
        ResellerStatisticsWidgetComponent
    ]
})
export class ComponentsModule {}
