import { Component, computed, effect, HostBinding, inject, signal } from '@angular/core';

import { BaseSidebarComponent } from '../../../../../../components/sidebar-components/base-sidebar/base-sidebar.component';
import { TicketSidebarService } from '../../../../../../core/services/ticket-sidebar.service';
import { LicensesStore } from '../../../../../services/stores/licenses.store';
import { OptionalType } from '../../../../../../core/models/types/optional.type';
import { LicenseConnectionModel } from '../../../../../models/license-connection.model';

@Component({
    selector: 'arc-ticket-base-data',
    templateUrl: './ticket-base-data.component.html',
    styleUrl: './ticket-base-data.component.scss'
})
export class TicketBaseDataComponent extends BaseSidebarComponent {
    @HostBinding('class') classes = 'h-full flex flex-col px-8 pt-8 smd:px-2 smd:pt-2 w-[500px] bg-dark text-white overflow-y-auto';
    readonly ticket = computed(() => this.ticketSidebarService.ticket());
    readonly license = computed(() => this.ticketSidebarService.license());

    readonly timeSpent = computed(() => {
        const totalTime = this.ticket()?.totalTime;
        if (!totalTime) {
            return '';
        }

        return this.getRoundedTime(totalTime);
    });

    readonly licenseConnection = signal<OptionalType<LicenseConnectionModel>>(undefined);
    readonly licenseUrl = computed(() => !!this.licenseConnection() ? `https://${this.licenseConnection()!.envUrl}` : undefined);

    private readonly ticketSidebarService = inject(TicketSidebarService);
    private readonly licenseStore = inject(LicensesStore);

    constructor() {
        super();

        effect(() => {
            const license = this.license();
            if (!!license) {
                this.licenseStore.getConnectionById(license.id).subscribe(x => {
                    this.licenseConnection.set(x.value);
                });
            }
        });
    }

    getRoundedTime(timeSpentMinutes: number): string {
        // Round to the nearest 0.5 minute
        const roundedMinutes = Math.round(timeSpentMinutes * 2) / 2;

        const hours = Math.floor(roundedMinutes / 60); // Get whole hours
        const minutes = roundedMinutes % 60; // Get remaining minutes

        if (hours > 0) {
            return `${hours}h ${minutes}min`;
        } else {
            return `${minutes}min`;
        }
    }
}
