import { Component, HostBinding, OnInit, inject } from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { map, filter } from 'rxjs';

import { BaseComponent } from '../components/abstractions/base.component';
import { LayoutService } from '../core/services/layout.service';
import { SettingsStorage } from '../core/services/storages/settings.storage';
import { TranslationService } from '../core/services/translation.service';
import { environment } from '../environments/environment';

@Component({
    selector: 'arc-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit {
    @HostBinding('class.dark') isDarkMode = false;
    @HostBinding('class.mity') isMity = false;

    private readonly settingsStorage = inject(SettingsStorage);
    private readonly layoutService = inject(LayoutService);
    private readonly matIconRegistry = inject(MatIconRegistry);
    private readonly domSanitizer = inject(DomSanitizer);
    private readonly translationService = inject(TranslationService);
    private readonly titleService = inject(Title);
    private readonly router = inject(Router);
    private readonly activatedRoute = inject(ActivatedRoute);

    constructor() {
        super();
        this.matIconRegistry.addSvgIcon(
            'arcavis_icon',
            this.domSanitizer.bypassSecurityTrustResourceUrl(
                environment.isMity ? '../assets/images/mity_icon_yellow.svg' : '../assets/images/arcavis_icon.svg'
            )
        );
        this.activatedRoute.queryParamMap.subscribe(params => {
            const ticketId = params.get('openTicketId');

            if (!!ticketId) {
                window.location.href = `${window.location.protocol}//${window.location.host}/support/tickets?Id=${ticketId}`;
            }
        });
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => {
                    let child = this.activatedRoute.firstChild;

                    while (!!child) {
                        if (!!child.firstChild) {
                            child = child.firstChild;
                        } else if (!!child.snapshot.data && !!child.snapshot.data['title']) {
                            return `Layout.Menu.${child.snapshot.data['title']}`;
                        } else {
                            return undefined;
                        }
                    }

                    return undefined;
                })
            )
            .subscribe((data?: any) => {
                const portalName = this.isMity ? 'Mity' : 'Arcavis';
                if (!!data) {
                    this.translationService.getTextAsync(data).then(txt => this.titleService.setTitle(`${portalName} | ${txt}`));
                } else {
                    this.titleService.setTitle(portalName);
                }
            });
    }

    ngOnInit(): void {
        this.isMity = environment.isMity;
        if (this.isMity) {
            this.setFavicon('../assets/images/mity_icon_yellow.svg');
        }
        this.isDarkMode = this.settingsStorage.getDarkMode() ?? false;

        this.layoutService.setDarkMode(this.isDarkMode);

        const darkModeChangedSubscription = this.layoutService.getThemeChangedObservable().subscribe(isDarkMode => {
            this.isDarkMode = isDarkMode;
            this.settingsStorage.saveDarkMode(isDarkMode);
        });

        this.addSubscriptions(darkModeChangedSubscription);
    }

    private setFavicon(href: string): void {
        const link: HTMLLinkElement = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
        link.rel = 'icon';
        link.href = href;
        document.getElementsByTagName('head')[0].appendChild(link);
    }
}
